import React from 'react';
import PropTypes from 'prop-types';
import styles from './Settings.module.css';

const Settings = props => {
    const layers = props.layers.filter( item => !item.metadata.isHidden );

    return (
        <div>
            <ul className={ styles.list }>
                { layers.map( item => {
                    const hasDescription = typeof item.metadata.description !== 'undefined';
                    const Tag = item.metadata.isToggleable ? 'button' : 'span';
                    const itemProps = {
                        className: styles.toggle,
                        ...(item.metadata.isToggleable && {
                            type: 'button',
                            onClick: props.onItemChange.bind(null, item.id)
                        })
                    };

                    return (
                        <li className={ styles.listItem } key={item.id}>
                            <Tag {...itemProps}>
                                { item.metadata.isToggleable && item.layout ?
                                    <span className={item.layout.visibility === 'visible' ? styles.toggleSwitchActive : styles.toggleSwitch}></span>
                                :
                                    <span className={styles.toggleSwitchDisabled}></span>
                                }

                                <span className={hasDescription ? styles.toggleLabelLg : styles.toggleLabel}>{item.metadata.title}</span>
                                {item.metadata.iconPath &&
                                    <img className={styles.toggleIcon} src={item.metadata.iconPath} alt=""/>
                                }
                            </Tag>
                            
                            { hasDescription && <p className={styles.description}>{item.metadata.description}</p> }
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

Settings.propTypes = {
    layers: PropTypes.array.isRequired,
    onItemChange: PropTypes.func.isRequired
};

export default Settings;