function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }

    return response;
}

export default function fetchJson( endpoint, callback ) {
    return new Promise(( resolve, reject ) => {
        fetch( endpoint )
            .then( handleErrors )
            .then(res => res.json())
            .then(
                (result) => {
                        // console.log(result);
                    resolve( result );
                },
                (error) => {
                    console.error('Error', error);
                    reject( error );
                }
            )
            .catch(( error => {
                console.error('Error', error);
                reject( error );
            }));
    });
}