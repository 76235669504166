import React from 'react';
import MapContext from './MapContext';
import isEmpty from 'lodash/isEmpty';
import slugify from '../../utils/slugify';
import fetchJson from '../../utils/fetchJson';

class MapProvider extends React.Component {
    constructor(props) {
        super(props);

        this.getLayerIds = this.getLayerIds.bind(this);
        this.getLayerById = this.getLayerById.bind(this);
        this.getFeature = this.getFeature.bind(this);
        this.setLayerVisibility = this.setLayerVisibility.bind(this);
        this.toggleLayerVisibility = this.toggleLayerVisibility.bind(this);
        this.setContext = this.setContext.bind(this);
        this.setLayerContext = this.setLayerContext.bind(this);

        this.state = {
            isInitialised: false,
            isNavActive: true,
            layers: {
                data: {},
                isLoaded: false,
                activeRoute: null,
                activeMarker: {}
            },
            getLayerIds: this.getLayerIds,
            getLayerById: this.getLayerById,
            getFeature: this.getFeature,
            setLayerVisibility: this.setLayerVisibility,
            toggleLayerVisibility: this.toggleLayerVisibility,
            setContext: this.setContext,
            setLayerContext: this.setLayerContext,
            ...props.config
        };
    }

    getData( item ){
        const newItem = { ...item };

        return fetchJson( newItem.source.path ).then((result) => {
            newItem.source.data = result;
            delete newItem.source.path;
        });
    }

    componentDidMount(){
        const data = JSON.parse(JSON.stringify(this.state.data));
        const reqs = [];

        Object.keys( data ).forEach( key => {
            data[key].forEach( item => {
                const req = this.getData( item );
                reqs.push( req );
            });
        });

        Promise.all( reqs ).then( () => {
            this.setState( prevState => {
                const newState = { ...prevState };
                newState.layers = {
                    ...prevState.layers,
                    data,
                    isLoaded: true
                }
                return newState;
            });
        });
    }

    setContext( state ) {
        this.setState( prevState => 
            ({ ...prevState, ...state })
        );
    }

    setLayerContext( state ){
        const newState = { ...state };

        Object.keys( newState ).forEach( key => {
            if( ( key === 'activeMarker' || key === 'activeRoute' ) && !isEmpty(newState[key]) ) {
                newState[key].timestamp = new Date();
            }
        });

        this.setState( prevState => ({ 
            ...prevState,
            layers: {
                ...prevState.layers,
                ...newState
            }
        }));
    }

    setLayerVisibility( layerId, isVisible ) {
        this.setState( prevState => {
            const newState = { ...prevState };
            const data = JSON.parse(JSON.stringify(prevState.layers.data));
            let position = {};

            for ( let key of Object.keys( data ) ) {
                let index = data[key].findIndex( item => item.id === layerId )

                 if( index > -1 ) {
                     position = { key, index };
                     break;
                 };
             }

            data[position.key][position.index].layout.visibility = isVisible ? 'visible' : 'none';

            newState.layers = {
                ...prevState.layers,
                data
            };

            return newState;
        });
    }

    toggleLayerVisibility( layerId ){
        const item = this.getLayerById( layerId );
        this.setLayerVisibility( layerId, item.layout.visibility === 'visible' ? false : true );
    }

    getLayerIds(){
        const ids = [];
        const { data } = this.state.layers;

        Object.keys( data ).forEach( key => {
            data[key].forEach( item => ids.push( item.id ) )
        });

        return ids;
    }

    getLayerById( id ){
        let item;
        const { data } = this.state.layers;
        
        id = slugify( id );

        for (let key of Object.keys( data )) {
            item = data[key].filter( item => slugify( item.id ) === id );

            // if no item found, search against name property
            if ( !item.length ) {
                item = data[key].filter( item => {
                    return item.source.data.features && item.source.data.features.filter( feature => {
                        return feature.properties.name && slugify( feature.properties.name ) === id;
                    }).length > 0;
                });
            }

            if( item.length ) break;            
        }

        return item.length ? item[0] : null;
    }

    getFeature( style, id ){
        id = slugify( id );

        const feature = style.source.data.features.filter( feature => {
            return feature.properties.name && slugify( feature.properties.name ) === id;
        });

        return feature.length ? { style, feature: feature[0] } : null;
    }

    render() {
        return (
            <MapContext.Provider value={this.state}>
                {this.props.children}
            </MapContext.Provider>
        )
    }
}

export default MapProvider;