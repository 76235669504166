import React from 'react'
import classnames from 'classnames';
import iconPath from '../../../assets/img/icons.svg';
import styles from './Icon.module.css';

const Icon = (props) => (
  <svg className={classnames( styles.icon, props.className )}>
    <use xlinkHref={`${iconPath}#icon-${props.name}`} />
  </svg>
)

export default Icon;