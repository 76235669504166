import { cycleStreetsKey, mapOptions } from './config.json';
import fetchJson from './utils/fetchJson';

const cyclestreets = {
    getMatch( waypoints, plan ) {
        const itinerarypoints = waypoints.join('|');
        return fetchJson(`https://www.cyclestreets.net/api/journey.json?key=${cycleStreetsKey}&itinerarypoints=${itinerarypoints}&plan=${plan}`);
    },

    generateGeojson( markers ) {
        const features = markers
            .filter( ( marker, index ) => index !== 0 )
            .map( marker => {
                const attributes = marker['@attributes'];
                const { points, ...properties } = attributes;
                const coordinates = points.split(' ').map( coord => 
                    coord.split(',').map( c => Number(c) )
                );

                return {
                    "type": "Feature",
                    properties,
                    "geometry": {
                        coordinates,
                        type: 'LineString'
                    }
                }
            });

        return {
            "id": "route",
            "type": "line",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": features
                }
            },
            ...mapOptions.routePlannerLine
        }
    }
}

export default cyclestreets;