import mapboxgl from 'mapbox-gl';
import MapboxDraw from 'mapbox-gl-draw';
import merge from 'lodash/merge';

class MapboxMap {
    static DEFAULTS = {
        padding: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20
        },
        map: {
            container: null
        },
        draw: {
            displayControlsDefault: false
        }
    }

    constructor( accessToken ) {
        mapboxgl.accessToken = accessToken;

        this.instance = null;
        this.layers = [];
        this.padding = MapboxMap.DEFAULTS.padding;
    }    

    initialise( options ){
        this.settings = merge({}, MapboxMap.DEFAULTS, options );
        this.instance = new mapboxgl.Map( this.settings.map );
        this.draw = new MapboxDraw( this.settings.draw );
        this.instance.addControl(this.draw);
       
        if( window.matchMedia('(min-width: 1024px)').matches ) {
            this.instance.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        }

        return this.instance;
    }

    showLayer( layer ){
        if( !this.instance.getLayer( layer.id ) ) return;

        this.instance.setLayoutProperty(layer.id, 'visibility', 'visible');    
        
        if( layer.type === 'line' ) {
            this.setLineCaps( layer );
        }
    }

    hideLayer( layerId ) {
        if( !this.instance.getLayer( layerId ) ) return;

        const layer = this.layers.filter( layer => layer.id === layerId )[0];

        this.instance.setLayoutProperty(layerId, 'visibility', 'none');
        this.setLineCaps( layer );

        if( layer.type === 'line' ) {
            this.setLineCaps( layer );
        }
    }

    toggleLayer( layerId ){
        if( !this.instance.getLayer( layerId ) ) return;
        const value = this.instance.getLayoutProperty(layerId, 'visibility');
        this.instance.setLayoutProperty(layerId, 'visibility', value === 'none' ? 'visible' : 'none');
        this.setLineCaps( this.layers.filter( layer => layer.id === layerId ) );
    }

    addLayers( data ){
        const promises = [];

        data.forEach( ( item, index ) => {
            const { metadata } = item;

            if( item.type === 'symbol' && metadata && metadata.iconPath ) {
                if( metadata && metadata.iconPath ) {
                    promises.push( new Promise( ( resolve, reject ) => {
                        this.instance.loadImage(metadata.iconPath, (error, image) => {
                            if (error) throw error;
                            
                            if( !this.instance.hasImage( 'image-' + item.id ) ) {
                                this.instance.addImage('image-' + item.id, image);
                            }

                            this.instance.addLayer( item );
                            this.instance.setLayoutProperty(item.id, 'icon-image', 'image-' + item.id);
                            this.instance.setLayoutProperty(item.id, 'icon-size', 0.5);
                            this.instance.setLayoutProperty(item.id, 'symbol-sort-key', 2);
                            
                            this.layers.push( item );
    
                            resolve( item );
                        })
                    }));
                } else {
                    this.instance.addLayer( item );
                    this.layers.push( item );
                }
            } else {
                const firstSymbolId = this.getFirstSymbol();

                this.instance.addLayer( item, firstSymbolId );

                if( metadata && metadata.showLabel ) {
                    this.instance.addLayer( 
                        this.generateLabelGeoJson( item ), 
                    firstSymbolId );
                }
                
                this.layers.push( item );
                this.setLineCaps( item );
            }
        });

        return Promise.all( promises ).then( symbols => this.bindHandlers() );
    }

     // Find the id of the first symbol layer
    getFirstSymbol() {
        let firstSymbolId;

        for (var i = 0; i < this.layers.length; i++) {
            if (this.layers[i].type === 'symbol') {
                firstSymbolId = this.layers[i].id;
                break;
            }
        }

        return firstSymbolId;
    }

    generateSymbolGeoJson( id, coord, iconSrc ) {
        return {
            "id": id,
            "type": "symbol",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": [
                        { 
                            "type": "Feature", 
                            "geometry": { 
                                "type": "Point", 
                                "coordinates": coord
                            }
                        }
                    ]
                }                        
            },
            "layout": {
                "icon-anchor": "bottom",
                "visibility": "visible",
                "icon-allow-overlap": true
            },
            "metadata": {
                "iconPath": iconSrc
            }
        }
    }

    generateLabelGeoJson( item ){
        const { metadata } = item;

        return {
            "id": item.id + "-label",
            "type": "symbol",
            "source": item.id,
            ...merge({}, this.settings.lineLabelStyle, metadata.label )
        }
    }

    removeLayer( id ){
        [ id + '-label', id + '-caps', id ].forEach( layerId => {
            if( this.instance.getLayer( layerId ) ) {
                this.instance.removeLayer( layerId );
            }
    
            if( this.instance.getSource( layerId ) ) {
                this.instance.removeSource( layerId );
            }
        });
        
        this.layers = this.layers.filter(layer => {
            return layer.id !== id;
        });
    }

    clear( layers ){
        (layers || this.layers).forEach( ( item ) => {
            this.removeLayer( item.id );
        });
    }

    bindHandlers(){
        this.layers.forEach( layer => {
            this.instance.on('mouseenter', layer.id, e => {
                e.features.forEach( feature => {
                    const layer = feature.layer;
                    const { type, metadata } = layer;

                    if( !metadata ) return;

                    if( ( ( type === 'line' && metadata.html ) && metadata.isInteractive ) || metadata.isInteractive ) {
                        this.instance.getCanvas().style.cursor = 'pointer';
                    }
                })
            });
                
            this.instance.on('mouseleave', layer.id, e => {
                this.instance.getCanvas().style.cursor = '';
            });
        });
    }

    updateLayerLayout( data ) {
        data.forEach( ( item ) => {
            Object.keys( item.layout ).forEach( key => {
                this.instance.setLayoutProperty(item.id, key, item.layout[key]);

                if( item.type === 'line' && key === 'visibility' ) {
                    this.setLineCaps( item );
                }
            });

            this.layers.filter( layer => layer.id === item.id )[0] = item;
        });
    }

    setLineCaps( style ){
        if( style.metadata && style.metadata.disableLineCaps ) return;
        
        const layerId = style.id + '-caps';

        if ( this.instance.getLayer( layerId ) ){
            this.instance.removeLayer( layerId );
        }

        if (this.instance.getSource( layerId )){
            this.instance.removeSource( layerId );
        }

        if( style.layout.visibility !== 'visible' ) return;

        const coords = ( style.source.data.geometry && style.source.data.geometry.coordinates ) ||
            ( style.source.data.features && style.source.data.features.flatMap( feature => feature.geometry.coordinates ) );

        if( !coords ) {
            throw new Error( 'MapboxMap.setLineCaps(): coordinates not defined' ); 
        }

        // Construct line-caps geojson
        this.instance.addLayer({
            "id": layerId,
            "type": "circle",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": coords[0]
                            }
                        },
                        {
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": coords[ coords.length - 1 ]
                            }
                        }
                    ]
                }
            },
            "paint": {
                "circle-radius": 8,
                "circle-color": style.paint['line-color']
            }
        }, style.id );
    }

    openPopup( coordinates, html, options ) {
        this.closePopup();

        this.popup = new mapboxgl.Popup({ 
                closeButton: false, 
                closeOnClick: true,
                offset: 35,
                ...options
            })
            .setLngLat( coordinates )
            .setHTML( html )
            .addTo( this.instance );
          
        this.instance.easeTo({
            center: coordinates,
            maxDuration: 300
        });
    }

    closePopup(){
        if( this.popup ) {
            this.popup.remove();
        }
    }

    getBounds( coordinates ){
        return coordinates.reduce( ( bounds, coord ) => {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[1]));
    }

    setPadding( options ) {
        Object.assign(this.settings.padding, options);
    }

    fitBounds( bounds ){
        const isIE = !!window.MSInputMethodContext && !!document.documentMode;

        this.instance.stop();
        this.instance.fitBounds(bounds, {
            padding: this.settings.padding,
            animate: !isIE, // Don't animate if IE11
            maxZoom: 18
        });
    }

    fitToLayerBounds( layers, includeSymbols = false ){
        let coordinates = [];

        (layers || this.layers).forEach( item => {
            const visibility = item.layout.visibility || this.instance.getLayoutProperty(item.id, 'visibility');
 
            if( ( !includeSymbols && item.type === 'symbol' ) || !visibility || visibility === 'none' ) return;
            
            if( item.source.data.geometry ) {
                coordinates = coordinates.concat( item.source.data.geometry.coordinates );
            } else if( item.source.data.features ) {
                // If feature collection, loop features
                item.source.data.features.forEach( feature => {
                    if( feature.geometry.type === 'LineString') {
                        coordinates = coordinates.concat( feature.geometry.coordinates );
                    } else {
                        coordinates = coordinates.concat( [ feature.geometry.coordinates ] );
                    }
                });
            }
        });

        if( coordinates.length ) {
            const bounds = this.getBounds( coordinates );
            this.fitBounds( bounds );
        }
    }

    showAllLayers(){
        this.layers.forEach( item => {
            this.instance.setLayoutProperty(item.id, 'visibility', 'visible');
        });

        this.fitToLayerBounds();
    }

    isFeatureRendered( layerId, key, value ){
        const feature = this.instance.queryRenderedFeatures(null, {
            layers: [ layerId ],
            filter: [ "==", key, value ]
        });

        return feature.length > 0;
    }

    getFeaturesAtPoint( point, layerIds ) {
        // Check to see if layers exist on map before querying
        const mapLayerIds = layerIds.filter( layerId => this.instance.getLayer( layerId ) );

        // Expand bounding box to make clicking easier
        const bbox = [[point.x - 5, point.y - 5], [point.x + 5, point.y + 5]];

        return this.instance.queryRenderedFeatures( bbox, { layers: mapLayerIds } );
    }

    resize(){
        this.instance.resize();
    }
}

export default MapboxMap;