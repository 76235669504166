import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Info.module.css';

class Info extends Component {
    constructor(props) {
        super(props);
        this.handleContentClick = this.handleContentClick.bind(this);
    }
    
    getContent() {
        return {__html: this.props.content };
    }

    handleContentClick(e) {
        e.preventDefault();
        
        if( e.target.href ) {
            window.open(e.target.href, '_blank');
        }
    }

    render() {
        const props = this.props;

        return (
            <div className={styles.root}>
                <h2 className={styles.title}>
                    {props.title}
                </h2>
                <div className={styles.body} dangerouslySetInnerHTML={this.getContent()} onClick={this.handleContentClick}>
                </div>
            </div>
        );
    }
}

Info.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
};

export default Info;