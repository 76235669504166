import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    isMobile,
    isTablet,
    isIPad13,
    isSafari,
    isMobileSafari,
    isChrome
} from "react-device-detect";
import { useCookies } from 'react-cookie';
import styles from './AddToHomescreen.module.css';
import Icon from '../UI/Icon/Icon';

function getFutureDate( days ){
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000))
    return date;
}

const AddToHomescreen = ({ label }) => {
    const [cookies, setCookie] = useCookies(['addtohomescreen']);
    const isSupportedDevice = 
        (isChrome && isMobile) ||
        (isChrome && isTablet) || 
        isMobileSafari ||
        isIPad13;
    const [ isVisible, setVisible ] = useState( isSupportedDevice && !cookies.addtohomescreen);
   
    const classname = classnames(styles.overlay, {
        [styles.isChromeMobile] : isChrome && isMobile,
        [styles.isChromeTablet] : isChrome && isTablet,
        [styles.isSafariMobile] : isSafari && isMobile && !isTablet,
        [styles.isSafariTablet] : (isSafari && isTablet ) || isIPad13
    });

    function handleBtnClick(){
        setVisible(false);
        setCookie('addtohomescreen', '1', {
            expires: getFutureDate(30)
        })
    }

    return isVisible ? (
        <div className={classname}>
            <div className={styles.dialog}>
                {label}
                <button className={styles.btn} onClick={handleBtnClick}>
                    <Icon name="close"/>
                </button>
            </div>
            <div className={styles.indicator}>
                <Icon name="arrow_alt"/>
            </div>
        </div>
    ) : null;
}

AddToHomescreen.propTypes = {

};

export default AddToHomescreen;