import React, { useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import fetchJson from '../../utils/fetchJson';
import MapContext from '../../context/map/MapContext';

import styles from './Geocoder.module.css';
import Icon from '../UI/Icon/Icon';

const initialState = {
    value: '',
    results: null,
    showSuggestions: false,
    activeSuggestion: 0
};

function reducer(state, action) {
    return { ...state, ...action };
}

const Geocoder = ({ 
    endpoint, 
    input, 
    onItemSelect, 
    placeholder, 
    minValue, 
    bbox, 
    showLocateBtn
}) => {
    const [ state, dispatch ] = useReducer(reducer, initialState);
    const { mapboxApiKey } = useContext( MapContext );
    const hasLocateBtn = showLocateBtn && navigator.geolocation;

    const handleChange = throttle((e) => {
        dispatch({ 
            value: e.target.value,
            showSuggestions: true, 
            activeSuggestion: 0
        });
    }, 100);

    function handleResultClick( feature, e) {
        if( state.results && state.results.features ) {
            onItemSelect( feature )
        }

        dispatch({ 
            value: feature.place_name,
            showSuggestions: false, 
            activeSuggestion: 0
        });
    }

    function handleKeyDown( e ) {   
        const { activeSuggestion, results, value } = state;

        if( !value.length ) return;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            if( results && results.features ) {
                onItemSelect( results.features[activeSuggestion] )
            }

            dispatch({
                activeSuggestion: 0,
                showSuggestions: false,
                value: results.features[activeSuggestion].place_name
            });
        }
        // // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
          if (activeSuggestion === 0) {
            return;
          }
    
          dispatch({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
          if (activeSuggestion - 1 === results.length) {
            return;
          }
    
          dispatch({ activeSuggestion: activeSuggestion + 1 });
        }
    }

    function locateUser() {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            reverseGeocode( coords.longitude, coords.latitude, result => {
                dispatch({ 
                    value: result.features[0].place_name,
                });

                onItemSelect( result.features[0] );
            });
        }, () => {});
    }

    function reverseGeocode( longitude, latitude, callback ) {
        fetchJson( `${endpoint}/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxApiKey}` )
            .then( callback )
    }

    function forwardGeocode( searchText, callback ) {
        fetchJson( `${endpoint}/mapbox.places/${searchText}.json?bbox=${bbox}&access_token=${mapboxApiKey}` )
            .then( callback );
    }


    useEffect(() => {
        dispatch({ results: null });

        if( !state.value || (state.value.length < minValue) || !state.showSuggestions ) return;

        forwardGeocode(state.value, result => {
            dispatch({ results: result })
        });
    }, [state.value]);


    return (
        <div className={styles.geocoder}>
            <input className={hasLocateBtn ? styles.inputLocate : styles.input } {...input} type="text" placeholder={placeholder} value={state.value} autoComplete="off" onKeyDown={handleKeyDown} onChange={ handleChange }/>

            {hasLocateBtn &&
                 <button className={styles.locateBtn} type="button" onClick={locateUser} aria-label="Get location">
                    <Icon name="locate"/>
                 </button>
            }
           
            {state.results && state.results.features && state.showSuggestions && 
                <ul className={styles.autocomplete}>
                    { state.results.features.map( (feature, index) => (
                        <li className={styles.autocompleteItem} onClick={ handleResultClick.bind(null, feature) } data-selected={state.activeSuggestion === index} key={feature.id}>
                            {feature.place_name}
                        </li>
                    )) }
                </ul>
            }
        </div>
    )
};

Geocoder.defaultProps = {
    minValue: 3,
    bbox: '-3.1015589135609787,51.06247599233342,-2.8916218311709656,51.17203507244622',
    endpoint: 'https://api.mapbox.com/geocoding/v5'
};

export default Geocoder;