import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import useMediaQuery from '../../hooks/useMediaQuery';
import slugify from '../../utils/slugify';
import isEmpty from 'lodash/isEmpty';
import MapContext from '../../context/map/MapContext';
import ListItem from '../UI/ListItem/ListItem';
import styles from './CycleRoutes.module.css';


const CycleRoutes = ({ labels }) => {
    const { layers, isInitialised, keyItems, ...mapCtx } = useContext(MapContext);
    const { isLoaded, activeRoute, activeMarker, data } = layers;
    const navData = { ...data };
    const params = useParams();
    const history = useHistory();
    const isDesktop = useMediaQuery();

    navData.layers = navData.layers.filter( item => {
        return item.type === 'symbol' && item.metadata.showInNavigation && !isEmpty(item.source.data)
    });

    function constructNavItem( itemData, icon, key ){
        const { id: itemId, feature, metadata } = itemData;
        const title = feature ? feature.properties.name : metadata.title;
        const id = feature ? feature.properties.name : itemId;
        const color = (metadata && metadata.listIconColor) || keyItems.routes[0].color;
        let activeId;

        if( feature && activeMarker.feature ) {
            activeId = activeMarker.feature.properties.name;
        } else if( itemId && activeRoute && activeRoute.id ) {
            activeId = activeRoute.id;
        }

        const isActive = activeId === id;

        return metadata && metadata.showInNavigation === false ? null : (
            <ListItem 
                id={id} 
                color={color} 
                iconImg={icon} 
                title={title} 
                onClick={handleNavItemClick.bind(null, itemData)} 
                isActive={isActive} 
                key={key}/>
        );
    }

    function handleNavItemClick( item ){
        if( item.feature ) {
            mapCtx.setLayerContext({ 
                activeRoute: !layers.activeRoute ? { id: 'all' } : layers.activeRoute, 
                activeMarker: item
            });
        } else {
            mapCtx.setLayerContext({ activeRoute: item });
        }
        
        if( !isDesktop ) {
            mapCtx.setContext({ isNavActive: false });
        }
    }


    // 
    // Show all routes on load
    //

    useEffect(() => {        
        if( !(isLoaded && isInitialised) ) return;

        if( params.route && params.route !== 'all' ) {
            mapCtx.setLayerContext({ 
                activeRoute: mapCtx.getLayerById( params.route )
            });
        }

        if ( ( params.route && params.route === 'all' ) || ( !params.route && isDesktop ) ) {
            mapCtx.setLayerContext({ activeRoute: { id: 'all' } });
        }

        if( params.marker ) {
            const layer = mapCtx.getLayerById( params.marker );

            if( layer ) {
                mapCtx.setLayerContext({ 
                    activeMarker: mapCtx.getFeature( layer, params.marker )
                });
            }
        } 
    }, [isLoaded, isInitialised]);


    // 
    // Handle routing
    //

    useEffect(() => {
        if( !activeRoute ) return;

        let querystring = '/cycle-routes';
        const marker = activeMarker.feature && activeMarker.feature.properties.name;
        
        if ( activeRoute ) {
            querystring += `/${slugify( activeRoute.id )}`;
        }

        if( marker ) {
            querystring += `/${slugify(marker)}`;
        }

        history.replace( querystring );
    }, [activeMarker, activeRoute, isInitialised]);


    //
    // Remove routes on unload
    //

    useEffect(() => {        
        return () => {
            mapCtx.setLayerContext({
                activeRoute: null,
                activeMarker: {}
            });
        }
    }, []);
        

    return (
        <div className={styles.routes}> 
            <ul className={styles.list} id="ui-layers">
                <ListItem 
                    id="all" 
                    iconSymbol={'map'} 
                    title={labels.routesAll} 
                    onClick={handleNavItemClick.bind(null, { id: 'all' })} 
                    isActive={activeRoute && activeRoute.id === 'all'}/>
                    
                { navData.routes.map( item => constructNavItem( item, null, item.id ) ) }
            </ul>
            
            { navData.layers.map( item => {
                return (
                    <React.Fragment key={item.id}>
                        <h2 className={styles.catHeader}>{item.metadata.title}</h2>

                        <ul className={styles.list}>
                            { item.source.data.features.map( ( feature, index ) =>
                                constructNavItem( { style: item, feature }, item.metadata.iconPath, index )
                            )}
                        </ul>
                    </React.Fragment>
                )
            })}
        </div>
    );
}

CycleRoutes.propTypes = {
    labels: PropTypes.object.isRequired
};

export default CycleRoutes;