import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RoutePlannerContext from '../../context/routeplanner/RoutePlannerContext';
import LocalisationsContext from '../../context/localisations/LocalisationsContext';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import Time from './Time/Time';
import Information from './Information/Information';
import Icon from '../UI/Icon/Icon';
import { getMiles } from '../../utils/distance';

import styles from './Directions.module.css';

const AccordionToggle = () => {
    return (
        <AccordionItemState>
            {({ expanded }) => {
                return (
                    <span className={!expanded ? styles.accordionToggle : styles.accordionToggleExpanded}>
                        {!expanded ? 'Show' : 'Hide'}
                    </span>
                )
            }}
        </AccordionItemState>
    );
};

const Directions = () => {
    const { localisations } = useContext( LocalisationsContext );
    const { end, directions, plan: currentPlan, setContext } = useContext( RoutePlannerContext );

    function handlePlanChanged( e ) {
        setContext({ plan: e.target.value });
    }

    function getIconName( label ) {
        let icon;

        switch ( label.toLowerCase() ) {
            case 'turn left':
            case 'sharp left':
                icon = 'turn_left';
                break;
            case 'turn right':
            case 'sharp right':
                icon = 'turn_right';
                break;
            default: 
                icon = label.toLowerCase().replace(/ /g, '_')
                break;
        }

        return icon;
    } 

    if( !directions ) return null;

    const itinerary = directions.length > 0 && directions[0]['@attributes'];

    return directions.length === 0 ? (
        <p>No results found</p>
    ) : (
        <>   
            <div className={styles.plans} onChange={handlePlanChanged}>
                {['Balanced', 'Fastest', 'Quietest'].map( plan => {
                    const value = plan.toLowerCase();
                    return (
                        <div className={styles.plan} key={value}>
                            <input className={styles.planRadio} type="radio" id={value} value={value} name="plan" defaultChecked={currentPlan === value}/>
                            <label className={styles.planLabel} htmlFor={value}>{plan}</label>
                        </div>
                    )
                })}
            </div>

            <p className={styles.timeDistance}>
                <Time secs={itinerary.time}/>
                &nbsp;
                <strong>{getMiles(itinerary.length).toFixed(1)}</strong> miles
            </p>
            
            <Accordion className={styles.accordion} allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={['directions']}>

                <AccordionItem className={styles.accordionItem} uuid="information">
                    <AccordionItemHeading>
                        <AccordionItemButton className={styles.accordionButton}>
                            {localisations.directions.toggleInfo}
                            <AccordionToggle/>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accordionPanel}>
                        <Information attributes={itinerary} labels={localisations.directions.information}/>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={styles.accordionItem} uuid="directions">
                    <AccordionItemHeading>
                        <AccordionItemButton className={styles.accordionButton}>
                            {localisations.directions.toggleDirections}
                            <AccordionToggle/>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accordionPanel}>
                        <ul className={styles.directions}>
                            { directions.map( ( item, index ) => {
                                if( index === 0 ) return null;

                                const { 
                                    turn, 
                                    name,
                                    distance,
                                    provisionName
                                } = item['@attributes'];

                                let turnLabel = (turn || 'straight on');
                                turnLabel = turnLabel[0].toUpperCase() + turnLabel.slice(1); 
                                const iconName = getIconName( turnLabel );
                
                                return (
                                    <li className={styles.direction} key={name + index}>
                                        <div className={styles.directionIcon}>
                                            <Icon name={iconName}/>
                                        </div>
                                        <div className={styles.directionBody}>
                                            <strong>{turnLabel}</strong> to <strong>{name}</strong><br/>
                                            <small data-provision={provisionName}>{`${distance}m`}</small>
                                        </div>
                                    </li>
                                )
                            })}
                            <li className={styles.direction}>
                                <div className={styles.directionIcon}>
                                    <Icon name="pin"/>
                                </div>
                                <div className={styles.directionBody}>
                                    Arrive at {end ? end.place_name : itinerary.finish}
                                </div>
                            </li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        </>
    );
}

Directions.propTypes = {

};

export default Directions;