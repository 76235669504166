import React from 'react';
import RoutePlannerContext from './RoutePlannerContext';

class RoutePlannerProvider extends React.Component {
    constructor(props) {
        super(props);

        this.setContext = this.setContext.bind(this);

        this.state = {
            waypoints: null,
            directions: null,
            plan: 'balanced',
            start: null,
            end: null,
            setContext: this.setContext
        };
    }

    setContext( state ) {
        this.setState( prevState => 
            ({ ...prevState, ...state })
        );
    }

    render() {
        return (
            <RoutePlannerContext.Provider value={this.state}>
                {this.props.children}
            </RoutePlannerContext.Provider>
        )
    }
}

export default RoutePlannerProvider;