import React, { useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import classnames from 'classnames';
import styles from './MapKey.module.css';

const MapKey = ({ items, isInset }) => {
    const isDesktop = useMediaQuery();
    const [isOpen, setOpen] = useState( isDesktop );
    const classname = classnames(styles.key, {
        [styles.isInset]: isInset,
        [styles.isOpen]: isDesktop || isOpen
    })

    return (
        <div className={classname}>
            { !isDesktop &&
                <button className={styles.button} type="button" onClick={ e => setOpen(!isOpen) } aria-expanded={isOpen}>
                    Key <span className={styles.buttonIcon}/>
                </button>
            }
            <ul className={styles.list}>
                { items.map( item => (
                    <li className={styles.keyItem} key={item.id}>
                        <span className={styles.keyColor} style={{ backgroundColor: item.color }}/>
                        {item.label}
                    </li>
                ))} 
            </ul>
        </div>
    );
}

export default MapKey;