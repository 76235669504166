import React from 'react';

function secsToHms( secs ) {
    secs = Number(secs);
    const h = Math.floor(secs / 3600);
    const m = Math.floor(secs % 3600 / 60);
    const s = Math.floor(secs % 3600 % 60);
    
    return { h, m, s }
}

const Time = ({ className, secs }) => {
    const time = secsToHms( secs );
    return (
        <time className={className}>
            {time.h > 0 && <><strong>{time.h}</strong> hr</>} <strong>{time.m}</strong> min
        </time>
    );
}

export default Time;