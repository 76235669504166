import React from 'react';

export default React.createContext({
    isInitialised: false,
    isNavActive: true,
    layers: {
        data: {},
        isLoaded: false,
        activeRoute: null,
        activeMarker: {}
    },
    getLayerIds: () => {},
    getLayerById: () => {},
    setLayerVisibility: () => {},
    toggleLayerVisibility:  () => {},
    setContext: () => {},
    setLayerContext: () => {}
});