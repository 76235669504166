import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.svg';
import splashBg from '../../assets/img/bg-splash.jpg';
import styles from './SplashScreen.module.css';
import imgNewFeature from '../../assets/img/banner-new-feature.svg';

const SplashScreen = ({ labels }) => {
    return (
        <div className={styles.splash} style={{ backgroundImage: `url(${splashBg})` }}>
            <div className={styles.body}>
                <img className={styles.logo} src={logo} alt=""/>
                <h1 className={styles.title}>{labels.title}</h1>
                <p className={styles.desc}>{labels.description}</p>

                <aside className={styles.newFeatures}>
                    <h2 className={styles.bannerHeading}><img src={imgNewFeature} alt={labels.newFeatures.heading}/></h2>
                    <h3 className={styles.featureHeading}>{labels.newFeatures.featureTitle}</h3>
                    <p>{labels.newFeatures.featureDesc}</p>
                </aside>


                <Link className={styles.btn} to="/cycle-routes">
                    {labels.enterButton}
                </Link>
            </div>
            <small className={styles.copy}>
                {labels.copyright.replace( '{{year}}', (new Date()).getFullYear() )}
            </small>
        </div>
    );
}

SplashScreen.propTypes = {

};

export default SplashScreen;