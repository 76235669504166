import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Panel.module.css';
import Icon from '../../UI/Icon/Icon';

class Panel extends Component {
    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }
    
    componentDidMount(){
        document.addEventListener('mousedown', this.handleOutsideClick );
        document.addEventListener('touchmove', this.handleOutsideClick );    
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleOutsideClick );
        document.removeEventListener('touchmove', this.handleOutsideClick );
    }
    
    handleOutsideClick(e) {
        if( this.props.onClose && !this.ref.current.contains( e.target ) ) {
            this.props.onClose();
        }
    }

    render(){
        const { 
            position,
            title, 
            intro,
            theme,
            closeLabel,
            onClose,
            children 
        } = this.props;

        const classname = classnames(styles[position], theme && styles[theme])
    
        return (
            <section className={classname} ref={this.ref}>
                {title &&
                    <div className={styles.titleBar}>
                        <h2 className={styles.title}>{title}</h2>
                    
                        {onClose &&
                            <button className={styles.closeBtn} type="button" onClick={onClose} title={closeLabel}>
                                <Icon name="close"/>
                            </button>
                        }
                    </div>
                }
                
                <div className={styles.body}>
                    { intro && 
                        <p className={styles.intro}>{intro}</p>
                    }
                    {children}
                </div>
            </section>
        );
    }
}

Panel.propTypes = {
    position: PropTypes.string.isRequired,
    title: PropTypes.string, 
    intro: PropTypes.string, 
    closeLabel: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired
};

Panel.defaultProps = {
    position: 'static'
}

export default Panel;