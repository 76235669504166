import React, { useContext, useEffect } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import RouteForm from '../RouteForm/RouteForm';
import Directions from '../Directions/Directions';
import MapContext from '../../context/map/MapContext';
import RoutePlannerContext from '../../context/routeplanner/RoutePlannerContext';
import LocalisationsContext from '../../context/localisations/LocalisationsContext';

import styles from './RoutePlanner.module.css';

const RoutePlanner = () => {
    const { localisations } = useContext( LocalisationsContext );
    const { layers, setLayerContext } = useContext( MapContext );
    const { directions, setContext: setPlannerContext } = useContext( RoutePlannerContext );
    const isDesktop = useMediaQuery({
        query: '(min-width: 1024px)'
    });

    useEffect(() => {
        return () => {
            setLayerContext({ activeRoute: null });

            setPlannerContext({
                directions: null,
                waypoints: null,
                start: null,
                end: null,
                plan: 'balanced'
            });
        }
    }, []);

    return (
        <>  
            {( !isDesktop || ( isDesktop && !layers.activeRoute ) ) &&
                <div className={styles.intro}>
                    <p>{localisations.routePlanner.line1}</p>
                    <p>
                        {localisations.routePlanner.line2Mobile}&nbsp;
                        {isDesktop && localisations.routePlanner.line2Desktop}
                    </p>
                    
                </div>
            }

            <RouteForm/>

            { directions && isDesktop &&
                <Directions/>
            }
        </>
    )
};

export default RoutePlanner;