import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import MapContext from '../../context/map/MapContext';
import Icon from '../UI/Icon/Icon';
import styles from './Navigation.module.css';
import logo from '../../assets/img/logo.svg';
import merchantLogo from '../../assets/img/merchant-logo.svg';
import cyclestreetsLogo from '../../assets/img/cyclestreets-logo.png';

const Navigation = ({ labels, children, onLogoClick }) => {
    const { isNavActive, ...mapCtx } = useContext(MapContext);

    function handleToggleClick(e){
        mapCtx.setContext({ isNavActive: !isNavActive });
    }

    return (
        <nav className={isNavActive ? styles.isActive : styles.root}>
            <button className={styles.btnToggle} type="button" onClick={handleToggleClick} aria-label={ labels.btnToggle } id="ui-nav-toggle">
                <Icon className={styles.controlIcon} name={ !isNavActive ? 'arrow_right' : 'arrow_left' }/>
            </button>
            
            <div className={styles.inner}>               
                <Link className={styles.brand} to="/cycle-routes/all" onClick={onLogoClick}>
                    <img src={logo} alt="" onClick={onLogoClick} id="ui-logo"/>
                </Link>

                {children}

                <aside className={styles.credits}>
                    <p>Powered by</p>

                    <a href="https://www.cyclestreets.net/" target="_blank" rel="noopener noreferrer">
                        <img src={cyclestreetsLogo} alt=""/>
                    </a>
                    <a href="https://merchant.agency/case-studies/somerset-county-council/" target="_blank" rel="noopener noreferrer">
                        <img src={merchantLogo} alt=""/>
                    </a>
                </aside>
            </div>
        </nav>
    );
}

Navigation.propTypes = {
    labels: PropTypes.object.isRequired, 
    onLogoClick: PropTypes.func.isRequired
};

export default Navigation;