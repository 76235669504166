import React, { useRef, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MapContext from '../../context/map/MapContext';
import LocalisationsContext from '../../context/localisations/LocalisationsContext';
import RoutePlannerContext from '../../context/routeplanner/RoutePlannerContext';
import { RoutesHeader, PlannerHeader } from './Header/Header';
import Panel from './Panel/Panel';
import Info from './Info/Info';
import Settings from './Settings/Settings';
import Map from './Map/Map';
import MapKey from './MapKey/MapKey';
import MapControls from './MapControls/MapControls';
import Directions from '../Directions/Directions';

import styles from './Main.module.css';
import logo from '../../assets/img/logo.svg';


const Main = ({ onLogoClick }) => {
    const [ isSettingsVisible, setSettingsVisible] = useState(false);
    const [ isInfoVisible, setInfoVisible] = useState(false);
    const [ isDrawMode, setDrawMode] = useState(false);

    const { isNavActive, keyItems, layers, ...mapCtx } = useContext( MapContext );
    const { directions } = useContext( RoutePlannerContext );
    const { localisations } = useContext( LocalisationsContext );

    const { activeRoute, activeMarker, data } = layers;
    const metadata = activeRoute && activeRoute.metadata;
    const hasInfo = metadata && metadata.hasOwnProperty('html');
    const activeTitle = (activeMarker.feature && activeMarker.feature.properties.name) || (metadata && metadata.title);
    const isDesktop = useMediaQuery();
    const location = useLocation();
    const headerRef = useRef(null);
    const isFullScreen = !((isInfoVisible && hasInfo) || (directions && directions.length > 0));

    function showInfoPanel(){
        setSettingsVisible( false );
        setInfoVisible( true );
    }

    function showSettingsPanel(){
        setSettingsVisible(true);
        setInfoVisible(false);
    }

    function setMenuActive( isActive ){
        mapCtx.setContext({ isNavActive: isActive });
    }

    function handleControlClick( name, e ){
        switch( name ) {
            case 'settings':
                showSettingsPanel();
                break;
            case 'info':
                showInfoPanel();
                break;
            case 'menu':
                setMenuActive( true );
                break;
            case 'all':
                mapCtx.setLayerContext({ activeRoute: { id: 'all' }});
                break;
            case 'draw':
                setDrawMode( !isDrawMode );
                break;
            default:
                console.error('Control name not known');
        }
    }

    function handleSettingsChange( layerId, e ){
        mapCtx.toggleLayerVisibility( layerId );

        if( activeRoute && typeof activeRoute.id !== 'undefined' && activeRoute.id === layerId ) {
            mapCtx.setLayerContext({ activeRoute: { id: 'all' }});
        }
    }

    function handlePanelClose( e ) {
        setSettingsVisible(false);
        setInfoVisible(false);
    }

    //
    // Disable draw on location change
    //
    
    useEffect(() => {
        setDrawMode( false );
    }, [location]);


    return (
        <div className={styles.root}>
            { !isDesktop &&
                <Switch>
                    <Route path="/cycle-routes">
                        <RoutesHeader 
                            title={activeTitle} 
                            labels={localisations.header} 
                            onControlClick={ handleControlClick } 
                            hasShowAll={activeRoute && activeRoute.id !== 'all'}
                            ref={headerRef} />
                    </Route>

                    { !isDesktop &&
                        <Route path="/route-planning">
                            <PlannerHeader ref={headerRef}/>
                        </Route>
                    }                
                </Switch>
            }
            
            <div className={classnames( styles.body, {
                [styles.isDrawMode]: isDrawMode,
                [styles.isMini]: !isFullScreen
            })}>
                <Link to="/cycle-routes/all" onClick={onLogoClick}>
                    <img className={styles.logo} src={logo} alt=""/>
                </Link>

                <Switch>
                    <Route path="/cycle-routes">
                        <MapKey items={keyItems.routes} isInset={isDesktop && isNavActive}/>
                    </Route>

                    <Route path="/route-planning">
                        <MapKey items={keyItems.planner} isInset={isDesktop && isNavActive}/>
                    </Route>
                </Switch>

                <MapControls 
                    labels={localisations.mapControls} 
                    onControlClick={handleControlClick} 
                    showInfoBtn={hasInfo && !isInfoVisible}
                    showDrawBtn={isDesktop && location.pathname === '/route-planning'}
                    isDrawEnabled={isDrawMode}/>
                
                <Map isDrawModeEnabled={isDrawMode}/>
            </div>
            
            <TransitionGroup component={null}>

                {isSettingsVisible &&
                    <CSSTransition key="settings" classNames="slideInRight" timeout={300}>
                        <Panel 
                            title={localisations.settings.header} 
                            intro={localisations.settings.intro} 
                            closeLabel={localisations.close} 
                            position="right"
                            onClose={handlePanelClose}>
                            <Settings layers={ data.layers } onItemChange={ handleSettingsChange }/>
                        </Panel>
                    </CSSTransition>
                }

                {isInfoVisible && hasInfo &&
                    <CSSTransition key="info" classNames={ isDesktop ? "slideInRight" : "slideInBottom" } timeout={300}>
                        <Panel 
                            title={localisations.routeInfo.header} 
                            closeLabel={localisations.close} 
                            position={ isDesktop ? "right" : "static" } 
                            onClose={handlePanelClose}>
                            <Info title={metadata.title} content={metadata.html}/>
                        </Panel>
                    </CSSTransition>
                }

                {directions && !isDesktop &&
                    <CSSTransition key="directions" classNames={ isDesktop ? "slideInRight" : "slideInBottom" } timeout={300}>
                        <Panel theme="dark">
                            <Directions/>
                        </Panel>
                    </CSSTransition>
                }

            </TransitionGroup>
        </div>
    );
}

Main.propTypes = {
    onLogoClick: PropTypes.func.isRequired
};

export default Main;