import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocalisationsContext from '../../context/localisations/LocalisationsContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import MapContext from '../../context/map/MapContext';
import RoutePlannerContext from '../../context/routeplanner/RoutePlannerContext';
import Geocoder from '../Geocoder/Geocoder';
import Icon from '../UI/Icon/Icon';
import cyclestreets from '../../cyclestreets';

import styles from './RouteForm.module.css';
import graphic from '../../assets/img/routeform-line.svg';

const RouteForm = () => {
    const { localisations }  = useContext( LocalisationsContext );
    const { layers, ...mapCtx } = useContext( MapContext );
    const { 
        waypoints, 
        directions,
        plan, 
        start, 
        end, 
        ...routeCtx
    } = useContext( RoutePlannerContext );
    const isDesktop = useMediaQuery();

    const hasDirections = directions && directions.length > 0;

    function getRoute( coords, plan ){
        cyclestreets.getMatch( coords, plan ).then( result => {
            routeCtx.setContext({ directions: result.marker || [] });

            if( result.marker ) {
                const route = cyclestreets.generateGeojson( result.marker );
                mapCtx.setLayerContext({ activeRoute: route });

                if( !isDesktop ) {
                    mapCtx.setContext({ isNavActive: false });
                }
            }            
        });
    }

    function doSearch(){
        if( !start || !end ) return;
        getRoute([ start.center, end.center ], plan );
    }

    function handleSubmit(e) {
        e.preventDefault();
        doSearch();
    }

    function handleBackClick(e) {
        routeCtx.setContext({
            directions: null,
            waypoints: null,
            start: null,
            end: null,
            plan: 'balanced'
        });

        mapCtx.setLayerContext({ activeRoute: null });
        
        if( !isDesktop ) {
            mapCtx.setContext({ isNavActive: true });
        }
    }

    function handleItemSelect(key, coord) {
        routeCtx.setContext({ [key]: coord });
    }
    

    useEffect(() => {
        if( waypoints ) {
            getRoute( waypoints, plan );
        }
    }, [ waypoints, plan ]);


    useEffect(() => {
        if( directions ) {
            getRoute( waypoints || [ start.center, end.center ], plan );
        }
    }, [ plan ]);


    // Auto search if user has already got results
    useEffect(() => {
        if( directions ) { 
            doSearch();
        }
    }, [ start, end ]);


    return (
        <div className={styles.container}>
            { hasDirections && 
                <button className={styles.backBtn} type="button" onClick={ handleBackClick }>
                    <Icon name="arrow_left"/>
                </button>
            }

            <form className={styles.form} onSubmit={ handleSubmit } key={directions}>
                <div className={styles.fields}>
                    <img className={styles.graphic} src={graphic} alt=""/>

                    <label className={styles.label} htmlFor="start">
                        {localisations.routeForm.from.label}
                    </label>
                    <Geocoder 
                        input={{ id: "start" }}
                        showLocateBtn={true}
                        onItemSelect={ handleItemSelect.bind(null, 'start') } 
                        placeholder={(start && start.place_name) || localisations.routeForm.from.placeholder}/>

                    <label className={styles.label} htmlFor="end">
                        {localisations.routeForm.to.label}
                    </label>
                    <Geocoder 
                        input={{ id: "end" }}
                        onItemSelect={ handleItemSelect.bind(null, 'end' ) } 
                        placeholder={(end && end.place_name) || localisations.routeForm.to.placeholder} />
                </div>

                { !layers.activeRoute &&
                    <button className={styles.button} type="submit">
                        {localisations.routeForm.button}
                    </button>
                }
            </form>
        </div>
    );
}

RouteForm.propTypes = {

};

export default RouteForm;