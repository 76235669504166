import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import useMediaQuery from '../../../hooks/useMediaQuery';
import MapContext from '../../../context/map/MapContext';
import LocalisationsContext from '../../../context/localisations/LocalisationsContext';
import RoutePlannerContext from '../../../context/routeplanner/RoutePlannerContext';
import MapboxMap from '../../../mapboxmap';

import styles from './Map.module.css';
import pin from '../../../assets/img/pin.png';

const Map = ({ isDrawModeEnabled }) => {    
    const { 
        isInitialised,
        mapboxApiKey, 
        mapOptions, 
        ...mapCtx
    } = useContext(MapContext);

    const { setContext: setPlanner } = useContext(RoutePlannerContext);
    const { localisations } = useContext( LocalisationsContext );
    const isDesktop = useMediaQuery();

    const {
        isLoaded,
        data,
        activeRoute,
        activeMarker
    } = mapCtx.layers;

    const { routes, layers } = data;
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);


    function handleMapZoomEnd( e ){  
        closePopup();
    }

    function handleMapClick( e ) {
        const features = mapInstance.current.getFeaturesAtPoint( e.point, mapCtx.getLayerIds() );
        const drawnFeatures = mapInstance.current.draw.getAll().features;

        if( features.length ) {
            const feature = features[0];
            const style = mapCtx.getLayerById( feature.layer.id );
            
            if( style.type === 'line' && style.metadata.isInteractive !== false ) {
                mapCtx.setLayerContext({ activeRoute: style });
            }

            if( feature.layer.type === 'symbol' && style.metadata.isInteractive ) {
                mapCtx.setLayerContext({ activeMarker: { style, feature } });
                openInfoPopup( feature, style.metadata.title );            
            }
        } else if( activeMarker ) {
            mapCtx.setLayerContext({ activeMarker: {} });
        }

        if( drawnFeatures.length ) {
            const coords = drawnFeatures[0].geometry.coordinates;
            const coord = coords[ coords.length - 1 ];

            if ( coords.length > 1 ) {
                mapInstance.current.openPopup( coord, localisations.routePlannerPopup, {
                    offset: 15
                });
            }                
        } 
    }
     
    function openInfoPopup( feature, category ){
        if( !feature.properties.name ) return;
        
        mapInstance.current.openPopup( feature.geometry.coordinates, `
            <h3>${feature.properties.name}</h3>
            <small>${category}</small>
        `);
    }

    function closePopup(){
        if( !activeMarker.feature ) return; 
            
        const { style, feature } = activeMarker;
        const { name } = feature.properties;

        if ( name && !mapInstance.current.isFeatureRendered( style.id, 'name', name ) ) {
            mapInstance.current.closePopup();
        }
    }
    
    function handleMarkerChanged(){
        if( !isEmpty( activeMarker ) ) {
            mapCtx.setLayerVisibility( activeMarker.style.id, true );
        }

        if( activeMarker.feature && activeMarker.style.metadata.isInteractive ) {
            openInfoPopup( activeMarker.feature, activeMarker.style.metadata.title );
        }
    } 

    function handleDraw({ features }) {
        setPlanner({ waypoints: features[0].geometry.coordinates } );
    }    

    // 
    // Init map and bind handlers
    //
    
    useEffect(() => {
        mapInstance.current = new MapboxMap( mapboxApiKey );

        const instance = mapInstance.current.initialise({
            ...mapOptions,
            map: {
                ...mapOptions.map,
                container: mapContainer.current
            }
        });
        
        instance
            .on('load', () => mapCtx.setContext({ isInitialised: true }))
            .on('zoomend', handleMapZoomEnd )
            .on('click', handleMapClick )
            .on('draw.create', handleDraw )
            .on('draw.update', handleDraw );
    }, []);


    // 
    // Handle draw mode change
    //

    useEffect(() => {
        if( !isInitialised ) return;

        const instance = mapInstance.current;
        const hasDrawn = instance.draw.getAll().features.length > 0;
        const isDrawMode = instance.draw.getMode() === 'draw_line_string';
        
        if( ( !isDrawModeEnabled && hasDrawn ) || ( isDrawModeEnabled && !isDrawMode ) ) {
            instance.draw.deleteAll();
        }

        if( ( isDrawModeEnabled && !hasDrawn ) || ( isDrawModeEnabled && !isDrawMode ) ) {
            instance.draw.changeMode( 'draw_line_string' );
        }         
    });


    //
    // Add data on map load
    // 

    useEffect(() => {  
        if( !isInitialised || !isLoaded ) return;
        
        mapInstance.current.setPadding({
            top: 50,
            bottom: 120,
            left: isDesktop ? 390 : 20,
            right: 20
        });   

        mapInstance.current.addLayers( layers );
    },[ isInitialised, isLoaded ]);


    // 
    // Handle layer changes
    //

    useEffect(() => {
        if( !isInitialised ) return;

        mapInstance.current.clear( layers );
        mapInstance.current.addLayers( layers );
    },[ JSON.stringify( layers ) ]);


    // 
    // Handle route change
    //

    useEffect(() => {      
        if( !isInitialised ) return;
        
        const map = mapInstance.current;
        let routesToShow;

        map.clear( routes );
        map.removeLayer( 'route' );
        map.removeLayer( 'route-end' );
        map.closePopup();

        if ( !activeRoute ) return;
        
        if( activeRoute.id === 'all' ) {
            routesToShow = routes;
        } else {
            routesToShow = [ activeRoute ];
        }

        if( activeRoute.id === 'route' ) {
            const features = activeRoute.source.data.features;
            const coordinates = features[features.length - 1].geometry.coordinates;
            const pinGeoJson = map.generateSymbolGeoJson('route-end', coordinates[coordinates.length - 1], pin );

            map.addLayers([ pinGeoJson ]);
        }
        
        map.addLayers( routesToShow ).then(() => {
            map.fitToLayerBounds(routesToShow, false);
        });
    }, [ JSON.stringify( activeRoute ) ]);


    // 
    // Open popup if activeMarker changes
    //

    useEffect(() => {
        handleMarkerChanged();
    },[ JSON.stringify( activeMarker ) ]);


    return (
        <div className={styles.map} ref={mapContainer}/>
    );
}

Map.propTypes = {
    isDrawModeEnabled: PropTypes.bool
};

export default Map;