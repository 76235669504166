import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/flat-map';
import 'core-js/fn/array/find';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/starts-with';
import 'svgxuse';

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from "react-router-dom";
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import MapProvider from './context/map/MapProvider';
import RoutePlannerProvider from './context/routeplanner/RoutePlannerProvider';
import LocalisationsProvider from './context/localisations/LocalisationsProvider';
import config from './config.json';
import localisations from './localisations.json';

import './index.css';

ReactDOM.render(
    <LocalisationsProvider data={localisations}>
        <MapProvider config={config}>
            <RoutePlannerProvider>
                <Router>
                    <App/>
                </Router>
            </RoutePlannerProvider>
        </MapProvider>
    </LocalisationsProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
