import React from 'react';
import styles from './Information.module.css';

const Information = ({ attributes, labels }) => {
    let ascent = 0;
    let descent = 0;
    const elevations = attributes.elevations.split(',').map( n => Number(n) );
    
    elevations.forEach(function(elevation, index) {
        var n = elevations[0 === index ? index : index - 1];

        if( (elevation - n) > 0 ) {
            ascent += elevation - n
        } else {
            descent += (elevation - n) * -1;
        }
    });
    
    return (
        <>
            <table className={styles.table}>
                <tbody>
                    <tr className={styles.row}>
                        <th>{labels.ascent}</th>
                        <td>{ascent}m</td>
                    </tr>

                    <tr className={styles.row}>
                        <th>{labels.descent}</th>
                        <td>{descent}m</td>
                    </tr>

                    <tr className={styles.row}>
                        <th>{labels.quietness}</th>
                        <td>{attributes.quietness}%</td>
                    </tr>

                    <tr className={styles.row}>
                        <th>{labels.crossingDelays}</th>
                        <td>{attributes.signalledCrossings}</td>
                    </tr>
                    
                    <tr className={styles.row}>
                        <th>{labels.trafficLights}</th>
                        <td>{attributes.signalledJunctions}</td>
                    </tr>

                    <tr className={styles.row}>
                        <th>{labels.caloriesBurnt}</th>
                        <td>{attributes.calories}</td>
                    </tr>
                </tbody>
            </table>

            <p className={styles.small}>* This figure is an indication based on an average adult size body. To learn more about how many calories you are likely to burn, visit the <a href="" target="_blank" rel="noopener noreferrer">NHS calorie calculator</a></p>
        </>
    );
}

export default Information;