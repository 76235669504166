import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import styles from './ListItem.module.css';

const ListItem = props => {
    return (
        <li className={styles.listItem}>
            <button className={props.isActive ? styles.listItemBtnActive : styles.listItemBtn } onClick={props.onClick} type="button">
                { props.color && !props.iconImg &&
                    <span className={styles.listItemKeyLine} style={{ backgroundColor: props.color}}></span>
                }
                { props.iconImg &&
                    <img className={styles.listItemKeyIcon} src={props.iconImg} alt=""/>
                }
                { props.iconSymbol &&
                    <Icon className={styles.listItemKeyIcon} name={props.iconSymbol}/>
                }
                <span className={styles.listItemLabel}>{props.title}</span>
                <Icon className={styles.listItemCaret} name="arrow_right"/>
            </button>
        </li>
    );
};

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    iconImg: PropTypes.string,
    iconSymbol: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool
};

export default ListItem;