import React from 'react';
import LocalisationsContext from './LocalisationsContext';

class LocalisationsProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            localisations: this.props.data
        };
    }

    render() {
        return (
            <LocalisationsContext.Provider value={this.state}>
                {this.props.children}
            </LocalisationsContext.Provider>
        )
    }
}

export default LocalisationsProvider;