import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from "react-transition-group";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import useMediaQuery from '../../hooks/useMediaQuery';
import MapContext from '../../context/map/MapContext';
import LocalisationsContext from '../../context/localisations/LocalisationsContext';
import SplashScreen from '../SplashScreen/SplashScreen';
import Navigation from '../Navigation/Navigation';
import CycleRoutes from '../CycleRoutes/CycleRoutes';
import RoutePlanner from '../RoutePlanner/RoutePlanner';
import Main from '../Main/Main';

import styles from './App.module.css';
import Icon from '../UI/Icon/Icon';
import AddToHomescreen from '../AddToHomescreen/AddToHomescreen';


const App = () => {
    const { isInitialised, layers, setLayerContext }  = useContext( MapContext );
    const { localisations }  = useContext( LocalisationsContext );
    const isDesktop = useMediaQuery();

    function resetView( e ) {
        setLayerContext({
            activeRoute: { id: 'all' }
        });
    }

    return (
        <TransitionGroup className={styles.root}>
            <AddToHomescreen label={localisations.addToHomescreen}/>

            <Switch>
                { !isDesktop ?                    
                    <Route exact path="/">
                        <SplashScreen labels={localisations.intro} />
                    </Route>
                    :
                    <Route exact path="/" render={() => (
                        <Redirect to="/cycle-routes/all"/>
                    )}/>
                }
            
                <Route>
                    <div className={styles.container}>
                        { layers.isLoaded &&
                            <Navigation 
                                labels={localisations.navigation}
                                onLogoClick={resetView}>
                                    {isDesktop && (!layers.activeRoute || layers.activeRoute.id !== 'route') &&
                                        <div className={styles.header}>
                                            <h1 className={styles.headerTitle}>
                                                {localisations.intro.title}
                                            </h1>
                                            <p className={styles.headerDesc}>
                                                {localisations.intro.description}
                                            </p>
                                        </div>
                                    }

                                    {(!isDesktop || (isDesktop && (!layers.activeRoute || layers.activeRoute.id !== 'route'))) &&
                                        <ul className={styles.tabs}>
                                            <li className={styles.tab}>
                                                <NavLink 
                                                    className={styles.tabLink}
                                                    to={isDesktop ? '/cycle-routes/all' : '/cycle-routes'}
                                                    onClick={resetView}
                                                    isActive={(match, location) => 
                                                        location.pathname.startsWith('/cycle-routes')
                                                    }>
                                                    <Icon className={styles.tabIcon} name="routes"/>
                                                    {localisations.tabs.cycleRoutes}
                                                </NavLink>
                                            </li>
                                            <li className={styles.tab}>
                                                <NavLink className={styles.tabLink} to="/route-planning">
                                                    <Icon className={styles.tabIcon} name="directions"/>
                                                    {localisations.tabs.routePlanner}
                                                    <small className={styles.tabBadge}>New</small>
                                                </NavLink>
                                            </li>
                                        </ul>    
                                    }                                  

                                    <Switch>
                                        <Route path="/cycle-routes/:route?/:marker?">
                                            {isInitialised &&
                                                <CycleRoutes labels={localisations.navigation}/>
                                            }
                                        </Route>

                                        <Route path="/route-planning">
                                            <RoutePlanner/>
                                        </Route>

                                        <Route exact path="/:route/:marker?" render={({ match }) => (
                                            <Redirect to={`/cycle-routes${match.url}`}/>
                                        )}/>                                            
                                    </Switch>
                                
                            </Navigation>
                        }

                        <Main onLogoClick={resetView}/>
                    </div>
                </Route>
            </Switch>
        </TransitionGroup>
    )
}

export default App;
