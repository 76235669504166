import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
import Icon from '../../UI/Icon/Icon';
import RouteForm from '../../RouteForm/RouteForm';

const RoutesHeader = React.forwardRef((props, ref) => {
    const { title, labels, onControlClick, hasShowAll } = props;

    return (
        <header className={styles.routesHeader} ref={ref}>
            <h2 className={styles.title}>{title || labels.defaultTitle}</h2>
            <div className={styles.controls}>
                <button className={styles.control} type="button" onClick={onControlClick.bind(null, 'menu')}>
                    <Icon className={styles.controlIcon} name="arrow_left"/>
                    {labels.menu}
                </button>
                { hasShowAll &&
                    <button className={styles.control} type="button" onClick={onControlClick.bind(null, 'all')}>
                        {labels.all}
                    </button>
                }
                
            </div>
        </header>
    );
});

RoutesHeader.propTypes = {
    title: PropTypes.string,
    labels: PropTypes.object.isRequired,
    onControlClick: PropTypes.func.isRequired,
    hasShowAll: PropTypes.bool
};


const PlannerHeader = React.forwardRef((props, ref) => {
    return (
        <header className={styles.plannerHeader} ref={ref}>
            <RouteForm/>
        </header>
    );
});

export { RoutesHeader, PlannerHeader };