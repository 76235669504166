import React from 'react';
import PropTypes from 'prop-types';
import styles from './MapControls.module.css';
import Icon from '../../UI/Icon/Icon';

const MapControls = ({ labels, showInfoBtn, showDrawBtn, isDrawEnabled, onControlClick }) => {
    return (
        <div className={styles.root}>
            <button className={showInfoBtn ? styles.controlInfoActive : styles.controlInfo} type="button" aria-label={labels.info} onClick={ onControlClick.bind(null, 'info') } id="ui-btn-info">
                <Icon className={styles.controlIcon} name="info"/>
            </button>

            <button className={styles.controlSettings} type="button" aria-label={labels.settings} onClick={ onControlClick.bind(null, 'settings') } id="ui-btn-settings">
                <Icon className={styles.controlIcon} name="settings"/>
            </button>

            { showDrawBtn &&
                <button className={isDrawEnabled ? styles.controlDrawActive : styles.controlDraw} type="button" aria-label={labels.settings} onClick={ onControlClick.bind(null, 'draw') } id="ui-btn-draw">
                    <Icon className={styles.controlIcon} name="draw"/>
                </button>
            }
        </div>
    );
};

MapControls.propTypes = {
    labels: PropTypes.object.isRequired,
    showInfoBtn: PropTypes.bool,
    onControlClick: PropTypes.func.isRequired
};

export default MapControls;